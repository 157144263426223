import Cookies from "js-cookie";
import React from "react";
import { useSelector } from "react-redux";

export const Footer = ({ footer }) => {
  // const logo = useSelector((state) => state?.logoReducer?.action);
  const logo = Cookies.get("logoImg")

  return (
    <>
      <footer>
        <div className="left bottom">
          <img
            src="./assets/media/videoReel.png"
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="center bottom">
          <img
            src="./assets/media/photoReel.png"
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="right bottom">
          <img src="./assets/media/action.png" alt="" className="img-fluid" />
        </div>
        <div className="row footer-content">
          <div className="col-md-6 contact-for">
            <p className="title20">Follow us on social media</p>
            <ul className="social">
              <li>
                <a
                  href={footer?.footer_faecbook_link}
                  className="heading52"
                  target="blank"
                >
                  <i className="fa-brands fa-square-facebook" /> Facebook{" "}
                </a>
              </li>
              <li>
                <a
                  href={footer?.footer_instagram_link}
                  className="heading52"
                  target="blank"
                >
                  <i className="fa-brands fa-square-instagram" /> Instagram{" "}
                </a>
              </li>
              <li>
                <a href={footer?.footer_youtube_link} className="heading52" target="blank">
                  <i className="fa-brands fa-square-youtube" /> youtube{" "}
                </a>
              </li>
              <li>
                <a href={footer?.footer_twitter_link} className="heading52" target="blank">
                <i className="fa-brands fa-square-x-twitter"/> twitter{" "}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 contact-content">
            <img
              src={logo}
              alt="brand icon"
              className="brandlogo img-fluid"
            />
            <ul className="details">
              <li>
                <i className="fa-solid fa-location-dot" />
                <p className="text24">{footer?.footer_address}</p>
              </li>
              <li>
                {/* <img
                  src="./assets/media/call.png"
                  alt="calling icon"
                  className="img-fluid"
                /> */}
                <i className="fa-solid fa-phone"></i>
                <a href={`tel:${footer?.footer_mob_no_1}`} className="text24">
                  {" "}
                  +91 {footer?.footer_mob_no_1}{" "}
                </a>
              </li>
              <li>
                {/* <img
                  src="./assets/media/call.png"
                  alt="calling icon"
                  className="img-fluid"
                /> */}
                <i className="fa-solid fa-phone"></i>
                <a href={`tel:${footer?.footer_mob_no_2}`} className="text24">
                  {" "}
                  +91 {footer?.footer_mob_no_2}{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright">
          <p className="me2">
            © 2023 CapturedMoment. All rights reserved.{" "}
          </p>
          <p className="me2">Presenting By Webbytroops.</p>
        </div>
      </footer>
    </>
  );
};
