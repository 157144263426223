import React, { useEffect, useState } from "react";
import { PortFoliyoModal } from "./PortFoliyoModal";
import { GetData } from "../../ApiHalper/ApiHelper";

export const PortFoliyo = ({ portfoliyoHeding, data }) => {
  const [modalData, setModalData] = useState({});
  const [portfolyoModal, setPortfolyoModal] = useState(false);
  const togglePortfolyoModal = () => setPortfolyoModal(!portfolyoModal);
  const [menuData, setMenuData] = useState({});
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    const filteredData = data.filter((item) => item.portfolios.length > 0);
    const newArray = filteredData.map((item, key) => {
      return {
        id: item.id,
        heading: item.heading,
        index: key,
      };
    });
    handleShowPortofoliyoData(newArray[0]?.id);
  }, []);

  const handleFillterPortFoliyoModal = (id) => {
    GetData(`customer/home-page/portfolio/${id}`).then((res) => {
      setModalData(res?.data);
      togglePortfolyoModal();
    });
  };

  const handleShowPortofoliyoData = (id) => {
    let allItem = [];
    data?.map((item) => {
      if (item?.id === id) {
        allItem = item;
        return;
      }
    });
    setActiveTab(id);
    setMenuData(allItem);
  };

  const handleAllPortFoliyo = () => {
    setActiveTab("All");
    const allPortfolios = { portfolios: [] };
    data?.forEach((item) => {
      if (item?.portfolios && item?.portfolios.length > 0) {
        item?.portfolios?.forEach((portfolio) => {
          allPortfolios?.portfolios?.push(portfolio);
        });
      }
    });
    setMenuData(allPortfolios);
  };
  return (
    <>
      <section className="portfolio" id="portfolio">
        <div className="service-menu">
          <div className="capturewomen" />
          <div className="photoreel" />
          <div className="videoreel" />
          <div className="content">
            <h1 className="heading52 mb-0">
              {portfoliyoHeding?.portfolio_title}
            </h1>
            <hr className="breakline" />
            <p className="text24">{portfoliyoHeding?.portfolio_heading}</p>
          </div>
          <div className="srvc-menu-bg">
            <ul className="nav title20 portfolio-items">
              <li>
                <button
                  className={`items ${activeTab == "All" && "active"}`}
                  onClick={handleAllPortFoliyo}
                >
                  All <span className="undrline" />
                </button>
              </li>
              {data?.length > 0 &&
                data
                  ?.filter?.((e) => e?.portfolios?.length > 0)
                  ?.map((item, key) => (
                    <li key={key}>
                      <button
                        className={`items ${activeTab == item?.id && "active"}`}
                        onClick={() => handleShowPortofoliyoData(item?.id)}
                      >
                        {item?.heading} <span className="undrline" />
                      </button>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
        <div className="portfolios">
          <div className="row">
            {menuData?.portfolios?.length > 0 &&
              menuData?.portfolios?.map((item, key) => (
                <div className="col-4" key={key}>
                  <a
                    className="porto-item"
                    // data-bs-toggle="modal"
                    // data-bs-target="#portfolioDetail"
                    onClick={() => handleFillterPortFoliyoModal(item?.id)}
                  >
                    <img
                      src={item?.main_image}
                      alt="portfolio thumbnail"
                      className="img-fluid object-height"
                    />
                    <div className="review">
                      <h1 className="title42 text-center">{item?.title}</h1>
                      <p className="text24 mb-4 mt-1 text-center text-ellipsis">
                        {item?.review?.description}
                      </p>
                      <div className="reviewer">
                        <img
                          src={
                             item?.review?.customer_image
                              ||item?.review?.customer?.image_path
                          }
                          className="img-fluid"
                        />
                        <div className="reviewer-details ms-3">
                          <h5 className="name text24">
                            {item?.review?.customer_name||item?.review?.customer?.customer_name}
                          </h5>
                        
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
          </div>
        </div>
      </section>

      <PortFoliyoModal
        modalData={modalData}
        setPortfolyoModal={{
          setPortfolyoModal,
          portfolyoModal,
          togglePortfolyoModal,
        }}
      />
    </>
  );
};



