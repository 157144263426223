import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { PortFoliyoModal } from "../portFoliyo/PortFoliyoModal";
import { GetData } from "../../ApiHalper/ApiHelper";
import { Header } from "../../commen/Header";
import { Footer } from "../../commen/Footer";

export const SrvcPortoDetail = () => {
  const [modalData, setModalData] = useState({});
  const [portfolyoModal, setPortfolyoModal] = useState(false);
  const togglePortfolyoModal = () => setPortfolyoModal(!portfolyoModal);
  const loaction = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  const handleFillterPortFoliyoModal = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    GetData(`customer/home-page/portfolio/${id}`).then((res) => {
      setModalData(res?.data);
      togglePortfolyoModal();
    });
  };

  return (
    <>
      <Header />
      <div className="after-landing-section">
        <div className="all-srvces">
          <h1 className="heading52 heading"><Link to={-1} className="backbtn" title="Back"><i className="fa-solid fa-left-long"></i></Link>Service</h1>
          <div className="srvc-info">
            <div id={`srvcCard1`} className={`srvc-card`}>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="srvc-left">
                    <h2 className="title42">{loaction?.state?.data?.heading}</h2>
                    <hr className="lightBlack breakline w-100" />
                    <p className="text24 me-lg-5 me-4">
                      {loaction?.state?.data?.main_description}
                    </p>
                    {loaction?.state?.data?.feature_type === 2 && (
                      <ul className="text20 me-lg-5 me-md-4">
                        {loaction?.state?.data.feature
                          .split("||")
                          .map((feature, index) => (
                            <li key={index}>{feature}</li>
                          ))}
                      </ul>
                    )}
                    {loaction?.state?.data?.feature_type === 1 && (
                      <ul className="text20 me-lg-5 me-md-4">
                        <li>{loaction?.state?.data?.feature}</li>
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="srvc-right outline-section">
                    <div className="bg center">
                      <svg className="img-outline" viewBox="0 0 599.27 627">
                        <defs>
                          <linearGradient
                            id="linear-gradient"
                            x1="73.43"
                            y1="541.27"
                            x2="527.7"
                            y2="59.24"
                            gradientTransform="matrix(1, 0, 0, -1, 0, 622.29)"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0" stopColor="#ffb906" />
                            <stop offset="0.41" stopColor="#ffd876" />
                            <stop offset="0.72" stopColor="#ffb906" />
                            <stop offset="1" stopColor="#ffd876" />
                          </linearGradient>
                        </defs>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path
                              id="circleBG"
                              d="M0,627c0-42,1-347.84,1-347.84L0,0H598.38c0,141.43,2,180,0,281,0,105.78.89,273.14.89,346H0Zm79.7-86.67C123.4,584,193.44,601.64,299,601.64S474.65,584,518.35,540.33,579.66,426.6,579.66,321,562,145.38,518.35,101.69,404.61,40.38,299,40.38,123.4,58,79.7,101.69,18.39,215.43,18.39,321,36,496.64,79.7,540.33Z"
                            />
                            <path
                              id="circle"
                              className="linergradient"
                              d="M66.38,553.66C19.6,506.89,1,432.19,1,320.71S19.6,134.54,66.38,87.77,187.85,22.43,299.32,22.43,485.49,41,532.27,87.77s65.34,121.47,65.34,232.94-18.57,186.18-65.34,233S410.8,619,299.32,619,113.15,600.43,66.38,553.66ZM68,552c46.07,46.08,119.94,64.66,231.28,64.66S484.53,598.08,530.61,552s64.65-119.94,64.65-231.29-18.57-185.2-64.65-231.28S410.67,24.77,299.32,24.77,114.11,43.35,68,89.43,3.38,209.37,3.38,320.71,22,505.92,68,552Z"
                            />
                          </g>
                        </g>
                      </svg>
                      <img
                        src={loaction?.state?.data?.main_image}
                        alt="GIF"
                        className="img-fluid img-inside"
                        loading="lazy"
                      />
                    </div>
                    <img
                      src={loaction?.state?.data?.top_right_image}
                      alt="additional event image"
                      className="img-fluid top-right additional-img"
                      loading="lazy"
                    />
                    <img
                      src={loaction?.state?.data?.bottom_right_image}
                      alt="additional event image"
                      className="img-fluid bottom-left square additional-img"
                      loading="lazy"
                    />
                    <img
                      src={loaction?.state?.data?.bottom_left_image}
                      alt="additional event image"
                      className="img-fluid bottom-left additional-img"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {loaction?.state?.data?.portfolios?.length > 0 && (
            <h1 className="heading52 heading"> Portfoliyo </h1>
          )}
          <div className="portfolios">
            <div className="row">
              {loaction?.state?.data?.portfolios?.length > 0 &&
                loaction?.state?.data?.portfolios?.map((item, key) => (
                  <div className="col-sm-4 col-6" key={key}>
                    <a
                      href="#"
                      className="porto-item"
                      onClick={(e) => handleFillterPortFoliyoModal(item?.id, e)}
                    >
                      <img
                        src={item?.main_image}
                        alt="portfolio thumbnail"
                        className="img-fluid object-height"
                      />
                      <div className="review">
                        <h3 className="title42 text-center">{item?.title}</h3>
                        <p className="text24 mb-4 mt-1 text-center text-ellipsis">
                          {item?.review?.description}
                        </p>
                        <div className="reviewer">
                          <img
                            src={
                              item?.review?.customer_image
                              ||item?.review?.customer?.image_path
                            }
                            alt="Reviewer image"
                            className="img-fluid"
                          />
                          <div className="reviewer-details ms-3">
                            <h5 className="name text24">
                              {item?.review?.customer_name||item?.review?.customer?.customer_name}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
            </div>
          </div>
          <Footer footer={loaction?.state?.footer} />
        </div>
      </div>
      <PortFoliyoModal
        modalData={modalData}
        setPortfolyoModal={{
          setPortfolyoModal,
          portfolyoModal,
          togglePortfolyoModal,
        }}
      />
    </>
  );
};
