import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
export const Sarvices = ({ services, header, footer, portfolios }) => {
  //  console.log(services,portfolios)
  const containerRef = useRef(null);
  const [activeCard, setActiveCard] = useState(1); // State to track the active card

  useEffect(() => {
    portfolios?.forEach((portfolioGroup) => {
      portfolioGroup.portfolios?.forEach((portfolio) => {
        const correspondingService = services?.find(
          (service) => service?.id === portfolio?.service_id
        );

        if (correspondingService) {
          if (!correspondingService?.portfolios) {
            correspondingService.portfolios = [];
          }
          correspondingService?.portfolios?.push(portfolio);
        }
      });
    });
  }, []);

  const handleChangeCard = (key) => {
    setActiveCard(key + 1);
  };

  return (
    <>
      <section className="service" id="srvc" ref={containerRef}>
        <div className="service-menu">
          <div className="capturewomen" />
          <div className="photoreel" />
          <div className="videoreel" />
          <div className="content">
            <h1 className="heading52 mb-0">{header?.service_title}</h1>
            <hr className="breakline" />
            <p className="text24">{header?.service_heading}</p>
          </div>
          <div className="srvc-menu-bg">
            <ul className="nav title20 service-items">
              {services
                ?.map((item, key) => (
                  <li key={key} onClick={() => handleChangeCard(key)}>
                    <button
                      className={`items ${activeCard - 1 === key && "active"}`}
                    >
                      {item?.heading}
                    </button>
                  </li>
                ))}
              <li>
                <Link
                  to={"service/List"}
                  className={`{ items }`}
                  state={{ footer }}
                >
                  {" "}
                  more{" "}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="srvc-info">
          {services
            ?.map((item, key) => (
              <div
                key={key}
                id={`srvcCard${key + 1}`}
                className={`srvc-card ${
                  key + 1 === activeCard ? "active" : "hide"
                }`}
              >
                <div className="row align-items-center">
                  <div className="col-md-6 mb-2">
                    <div className="srvc-left">
                      <h2 className="title42">{item?.heading}</h2>
                      <hr className="lightBlack breakline w-100" />
                      <p className="text24 me-lg-5 me-4">
                        {item?.main_description}
                      </p>
                      {item?.feature_type === 2 && (
                        <ul className="text20 me-lg-5 me-md-4">
                          {item.feature.split("||").map((feature, index) => (
                            <li key={index}>{feature}</li>
                          ))}
                        </ul>
                      )}
                      {item?.feature_type === 1 && (
                        <ul className="text20 me-lg-5 me-md-4">
                          <li>{item?.feature}</li>
                        </ul>
                      )}
                    </div>
                    <Link
                      to={"service/Detail"}
                      state={{ data: item, footer }}
                      className="btns btns--calypso"
                    >
                      <span>See More</span>
                    </Link>
                  </div>
                  <div className="col-md-6">
                    <div className="srvc-right outline-section">
                      <div className="bg center">
                        <svg className="img-outline" viewBox="0 0 599.27 627">
                          <defs>
                            <linearGradient
                              id="linear-gradient"
                              x1="73.43"
                              y1="541.27"
                              x2="527.7"
                              y2="59.24"
                              gradientTransform="matrix(1, 0, 0, -1, 0, 622.29)"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop offset="0" stopColor="#ffb906" />
                              <stop offset="0.41" stopColor="#ffd876" />
                              <stop offset="0.72" stopColor="#ffb906" />
                              <stop offset="1" stopColor="#ffd876" />
                            </linearGradient>
                          </defs>
                          <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <path
                                id="circleBG"
                                d="M0,627c0-42,1-347.84,1-347.84L0,0H598.38c0,141.43,2,180,0,281,0,105.78.89,273.14.89,346H0Zm79.7-86.67C123.4,584,193.44,601.64,299,601.64S474.65,584,518.35,540.33,579.66,426.6,579.66,321,562,145.38,518.35,101.69,404.61,40.38,299,40.38,123.4,58,79.7,101.69,18.39,215.43,18.39,321,36,496.64,79.7,540.33Z"
                              />
                              <path
                                id="circle"
                                className="linergradient"
                                d="M66.38,553.66C19.6,506.89,1,432.19,1,320.71S19.6,134.54,66.38,87.77,187.85,22.43,299.32,22.43,485.49,41,532.27,87.77s65.34,121.47,65.34,232.94-18.57,186.18-65.34,233S410.8,619,299.32,619,113.15,600.43,66.38,553.66ZM68,552c46.07,46.08,119.94,64.66,231.28,64.66S484.53,598.08,530.61,552s64.65-119.94,64.65-231.29-18.57-185.2-64.65-231.28S410.67,24.77,299.32,24.77,114.11,43.35,68,89.43,3.38,209.37,3.38,320.71,22,505.92,68,552Z"
                              />
                            </g>
                          </g>
                        </svg>
                        <img
                          src={item?.main_image}
                          alt="GIF"
                          className="img-fluid img-inside"
                          loading="lazy"
                        />
                      </div>
                      <img
                        src={item?.top_right_image}
                        alt="additional event image"
                        className="img-fluid top-right additional-img"
                        loading="lazy"
                      />
                      <img
                        src={item?.bottom_right_image}
                        alt="additional event image"
                        className="img-fluid bottom-left square additional-img"
                        loading="lazy"
                      />
                      <img
                        src={item?.bottom_left_image}
                        alt="additional event image"
                        className="img-fluid bottom-left additional-img"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </section>
    </>
  );
};
