/*
 * Api helper File
 * Use for all Api.
 **/
/*
npm run build
*/
import axios from "axios";
import Cookies from "js-cookie";
import cookie from "react-cookies";


// export function APPURL() {
//   return APP_URL;
// }
//Post Data Functions.

function getBaseUrl() {
  const MainUrl = Cookies.get("baseurl")
  // console.log(`https://${MainUrl}/api/`)
  return `${MainUrl}/api/`; // Replace with your dynamic base URL source.
  // return "https://demotwo.webbytroops.in/api/";
  // return "https://demo.webbytroops.in/api/";
  // return "https://mumu.graphterstudio.com/api/";

}

export function PostData(url, data) {
  const MainUrl = getBaseUrl();

  // body..
  //
  var headers = {
    "Content-Type": "application/json",
    "X-localization": "en",
  };
  return axios
    .post(MainUrl + url, data, { headers: headers })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}
//Get Data Functions.
export function GetData(url, data) {
  // body...
  const MainUrl = getBaseUrl();
  // const MainUrl = `${baseUrl}${url}`;

  var headers = {
    "Content-Type": "application/json",
    //'Authorization':'Bearer '+Token,
    //'X-localization':'en'
  };
  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(MainUrl + url, data, { headers: headers })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      if (error.response) {
        let errorStatus = JSON.parse(JSON.stringify(error.response));
        //console.log(errorStatus.data);
        return errorStatus;
      }
    });
}
//Post Data with token
//Post Data Functions.
export function PostDataWithToken(url, data) {
  const MainUrl = getBaseUrl();

  // body..
  //
  const token = Cookies.get("token");
  var headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
    // Authorization: token
  };
  return axios
    .post(MainUrl + url, data, { headers: headers })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

export function DeleteDataWithToken(url) {
  const MainUrl = getBaseUrl();

  const token = Cookies.get("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios
    .delete(MainUrl + url, { headers: headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      return errorStatus;
    });
}

export function PutDataWithToken(url, data) {
  const MainUrl = getBaseUrl();

  const token = Cookies.get("token");
  var headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios
    .put(MainUrl + url, data, { headers: headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      return errorStatus;
    });
}

//Post Data Functions.
export function PostImageDataWithToken(url, data) {
  const MainUrl = getBaseUrl();

  // body..
  //
  let tokens = "";
  if (cookie.load("telimedicineToken")) {
    tokens = cookie.load("telimedicineToken");
  }
  var headers = {
    "Content-Type": "multipart/form-data",
    // Authorization: "Bearer " + tokens,
    Authorization: tokens,
  };
  return axios
    .post(MainUrl + url, data, { headers: headers })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}
//Get Data with token Functions.
export function GetDataWithToken(url, data) {
  const MainUrl = getBaseUrl();

  let tokens = "";

  if (cookie.load("token")) {
    tokens = cookie.load("token");
  }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
      // Authorization: tokens,
    },
    params: {},
  };

  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(MainUrl + url, config)
    .then((res) => {
      //console.log(res);
      return res.data;
    })
    .catch((error) => {
      console.log("errors", error);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

export function GetDataWithTokenForLazy(url, data) {
  // const MainUrl = getBaseUrl();

  let tokens = "";

  if (cookie.load("token")) {
    tokens = cookie.load("token");
  }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
      // Authorization: tokens,
    },
    params: {},
  };

  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(url, config)
    .then((res) => {
      //console.log(res);
      return res.data;
    })
    .catch((error) => {
      console.log("errors", error);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

export function GustGetDataWithTokenForLazy(url, data) {
  // const MainUrl = getBaseUrl();

  let tokens = "";

  if (cookie.load("gustToken")) {
    tokens = cookie.load("gustToken");
  }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
      // Authorization: tokens,
    },
    params: {},
  };

  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(url, config)
    .then((res) => {
      //console.log(res);
      return res.data;
    })
    .catch((error) => {
      console.log("errors", error);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

export function PostVideoDataWithToken(url, data, onUploadProgress) {
  const MainUrl = getBaseUrl();

  // body..
  //
  let tokens = "";
  if (cookie.load("telimedicineToken")) {
    tokens = cookie.load("telimedicineToken");
  }
  var headers = {
    "Content-Type": "multipart/form-data",
    // Authorization: "Bearer " + tokens,
    Authorization: tokens,
  };
  return axios
    .post(MainUrl + url, data, {
      headers: headers,
      onUploadProgress: onUploadProgress,
    })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

// FOR GUEST APIS

export function GetDataWithTokenGust(url, data) {
  const MainUrl = getBaseUrl();

  let tokens = "";

  if (cookie.load("gustToken")) {
    tokens = cookie.load("gustToken");
  }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
      // Authorization: tokens,
    },
    params: {},
  };

  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(MainUrl + url, config)
    .then((res) => {
      //console.log(res);
      return res.data;
    })
    .catch((error) => {
      console.log("errors", error);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

export function PostDataWithTokenNodeServerFormData(url,data) {
  // body..
  //
  const token = Cookies.get("token");
  var headers = {
    // "Content-Type": "application/json", 
    'Content-Type': 'multipart/form-data',
    "access-token": token,
  };
  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      // console.log(res);
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      // return error;
      let errorStatus = error?.response?.data;
      return errorStatus;
    });
}


export function PostDataWithTokenNodeServer(url,data) {
  // body..
  //
  const token = Cookies.get("token");
  var headers = {
    "Content-Type": "application/json", 
    // 'Content-Type': 'multipart/form-data',
    "access-token": token,
  };
  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      // let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      // return errorStatus;
    });
}






