import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import AvatarEditor from "react-avatar-editor";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { PostDataWithToken } from "../../../ApiHalper/ApiHelper";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const Profile = ({ activeClass }) => {
  const userInfo = useSelector((state) => state?.userInfo?.action?.customer);
  const [CropImageModal, setCropImageModal] = useState(false);
  const toggleCropImageModal = () => setCropImageModal(!CropImageModal);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate()

  // console.log(userInfo, 'check user information')
  var editor = "";
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 1,
    croppedImg: undefined,
  });

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
    setCropImageModal(false);
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleSave = (e) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (setEditorRef) {
      const canvasScaled = editor?.getImageScaledToCanvas();
      const croppedImg = canvasScaled?.toDataURL("image/jpeg", 0.9); // Adjust the quality (0.9 is a high quality)
      // const croppedImg = canvasScaled.toDataURL();
      // console.log(croppedImg);

      setPicture({
        ...picture,
        img: null,
        cropperOpen: false,
        croppedImg: croppedImg,
      });
      setCropImageModal(false);

      PostDataWithToken("customer/upload/cover-image", {
        img: croppedImg,
      }).then((res) => {
        // console.log(res);
        if (res?.success) {
          userData.customer.cover_img_path = res?.data?.cover_img_path;
          // const updatedData = JSON.stringify(userData);
          localStorage.setItem("userData", JSON?.stringify(userData));
        } else if (res.status === 401) {
          navigate("/login")
          Cookies.remove("token");
          toast.error('Session Expire ! Please Login again')

        }
      });
    }
  };

  const handleFileChange = (e) => {
    let url = URL?.createObjectURL(e?.target?.files[0]);
    // console.log(url);
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true,
    });
    setCropImageModal(true);
  };

  return (
    <>
      <div
        className="tanent-profile"
        style={{
          backgroundImage: `url(${picture?.croppedImg ||
            userData.customer?.cover_img_path ||
            userInfo?.cover_img_path
            })`,
        }}
      >
        <div className="profile">
          <div className="clientimg d-sm-inline-block d-none">
            {/* <div className="addclientimg position-relative" title="Add Client Image">
              <label htmlFor="tanentimg"><i class="fa-solid fa-user-pen"></i></label>
              <input id="tanentimg" type="file" accept="image/*" onChange={handleFileChange} className="d-none" />
            </div> */}
            <img
              src={userInfo?.image_path}
              alt="Client Images"
              className="client-dp"
            />
          </div>
          <div className="Profile-content">
            <div className="clientimg d-inline-block d-sm-none">
              {/* <div className="addclientimg position-relative" title="Add Client Image">
              <label htmlFor="tanentimg"><i class="fa-solid fa-user-pen"></i></label>
              <input id="tanentimg" type="file" accept="image/*" onChange={handleFileChange} className="d-none" />
            </div> */}
              <img
                src={userInfo?.image_path}
                alt="Client Images"
                className="client-dp"
              />
            </div>
            <div className="bio">
              <div className="left">
                <p className="name title24 mb-2">
                  {userInfo?.firstname} {userInfo?.lastname}
                </p>
                <p className="email text20 mb-2">
                  <i className="fa-solid fa-envelope" />
                  <span className="ms-1"> {userInfo?.email} </span>
                </p>
                <p className="address text20 mb-2">
                  <i className="fa-solid fa-location-dot" />
                  <span className="ms-1">
                    {userInfo?.city_name} {userInfo?.state_name} {userInfo?.country_name}
                  </span>
                </p>
              </div>
              <div className="right">
                <div className="addcoverphoto" title="Add Cover Photo">
                  <label htmlFor="coverphoto">
                    <svg viewBox="0 0 24 24" fill="none">
                      <path
                        d="M13 5H9.32843C8.79799 5 8.28929 5.21071 7.91421 5.58579L7.08579 6.41421C6.71071 6.78929 6.20201 7 5.67157 7H4C2.89543 7 2 7.89543 2 9L2 19C2 20.1046 2.89543 21 4 21H18C19.1046 21 20 20.1046 20 19V12M17 5H23M20 8V2M11 18C13.2091 18 15 16.2091 15 14C15 11.7909 13.2091 10 11 10C8.79086 10 7 11.7909 7 14C7 16.2091 8.79086 18 11 18Z"
                        stroke="#000000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </label>
                  <input
                    id="coverphoto"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="d-none"
                  />
                </div>
              </div>
            </div>
            <div className="project-details">
              <div className="detail-box">
                <p className="detail text24">
                  {userInfo?.dashboard?.total_paid}
                </p>
                <p className="info text20">Total Paid</p>
              </div>
              <div className="detail-box">
                <p className="detail text24">
                  {userInfo?.dashboard?.total_events}
                </p>
                <p className="info text20">Projects</p>
              </div>
              <div className="detail-box">
                <p className="detail text24">
                  {userInfo?.dashboard?.in_process_event}
                </p>
                <p className="info text20">In Process</p>
              </div>
              <div className="detail-box">
                <p className="detail text24">{userInfo?.dashboard?.upcoming}</p>
                <p className="info text20">Upcoming Event</p>
              </div>
            </div>
          </div>
        </div>
        <ul className="navigate-tabs title20">
          <li>
            {" "}
            <Link
              to={"/profile/Event"}
              className={`tab ${activeClass == "events" && "active"}`}
            >
              EVENTS
            </Link>
          </li>
          <li>
            {" "}
            <Link
              to={"/invoices"}
              className={`tab ${activeClass == "invoice" && "active"}`}
            >
              INVOICE
            </Link>
          </li>
          {/* <li>
            {" "}
            <a to={"/setting"} className="tab">
              SETTING
            </a>
          </li> */}
          <li>
            {" "}
            <Link
              to={"/Event/Token"}
              className={`tab ${activeClass == "EventToken" && "active"}`}
            >
              EVENTS TOKEN
            </Link>
          </li>
          <li>
            {" "}
            <Link
              to={"/faceRecognize"}
              className={`tab ${activeClass == "faceRecognize" && "active"}`}
            >
              FACE RECOGNIZE 
            </Link>
          </li>
        </ul>
      </div>

      <Modal
        isOpen={CropImageModal}
        toggle={toggleCropImageModal}
        // style={{ maxWidth: "1300px" }} // Adjust the width as needed
        className="custom-modal modal-lg" // Add a custom class name to the modal
      >
        <ModalHeader toggle={toggleCropImageModal} id="staticBackdropLabel">
        </ModalHeader>
        <ModalBody>

          {picture.cropperOpen && (
            <div>
              <AvatarEditor
                ref={setEditorRef}
                image={picture.img}
                border={20}
                color={[0, 0, 0, 0.6]} // RGBA
                rotate={0}
                className="img-cropper"
                scale={1/picture.zoom}
                imageSmoothingQuality="high" 
                width={500}
                height={200} 
              />
              <div
                aria-label="raceSlider"
                value={picture.zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={handleSlider}
              ></div>
            </div>
          )}

        </ModalBody>
        <ModalFooter>
          <button className="btns btns--calypso me-3" variant="contained" onClick={handleCancel}>
            <span>
              Cancel
            </span>
          </button>
          <button className="btns btns--calypso" onClick={handleSave}>
            <span>
              Save
            </span>
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};



