import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { LoginWithCode } from "./LoginWithCode";
import { PostData } from "../ApiHalper/ApiHelper";
import { Link, useNavigate, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { BtnLoader } from "../commen/BtnLoader";
import { actionUserLoggedIn } from "../store/Action";
import { useDispatch } from "react-redux";

export const Login = () => {
  const [btnLoader, setBtnLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const history = useHistory();
  const logoimg = Cookies.get('logoImg')

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  // useEffect(() => {
  //   const lastPageUrl = document.referrer;
  //   console.log("Last Page URL:", lastPageUrl);
  // }, []);

  const handleLogin = (data) => {
    setBtnLoader(true);
    PostData("customer/login", data).then((res) => {
      if (res?.success) {
        console.log("this is login ///////////////", res?.data)
        Cookies.set("token", res?.data?.token);
        localStorage.setItem("userData", JSON.stringify(res?.data));
        setBtnLoader(false);
        dispatch(actionUserLoggedIn.isLoggedInSlice(true));
        navigate("/profile/Event");
      } else {
        // console.log(res?.data?.message);
        toast.error(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setBtnLoader(false);
      }
    });
  };

  return (
    <>
      <section className="login">
        <div className="login-right">
          {/* Brand Logo */}
          <Link to='/'>
            <img
              src={`${logoimg}`}
              alt="brand-logo-image"
              className="img-fluid brandlogo"
            />
          </Link>
          <div className="login-sec">
            <div className="login-option">
              {/* Login tabs */}
              <span>Login With</span>
              <span className="title42 mx-xxl-3 mx-2">:</span>
              <ul className="nav">
                <li className="nav-item">
                  <button
                    type="button"
                    className="btn"
                    data-bs-toggle="tab"
                    data-bs-target="#inputCode"
                  >
                    Code
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className="btn active"
                    data-bs-toggle="tab"
                    data-bs-target="#inputGmail"
                  >
                    E-mail
                  </button>
                </li>
              </ul>
            </div>
            {/* Login Form Section */}
            <div className="tab-content">
              <div className="tab-pane fade show active" id="inputGmail">
                <div className="detail">
                  <h1>Welcome back</h1>
                  <p>
                    Please Enter your{" "}
                    <strong>E-mail and password</strong> or login
                    credentials or login credentials
                  </p>
                </div>
                <form>
                  <div
                    className={`position-relative iconinputs ${errors.email ? "geterror" : ""
                      }`}
                  >
                    <label htmlFor="Code" className="form-label">
                      E-mail
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Code"
                      placeholder="Enter your mail here"
                      {...register("email", {
                        required: " ",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: " ",
                        },
                      })}
                    />
                    {errors.email && errors.email.message && (
                      <div className="f-error text-danger">
                        <i className="fa-solid fa-xmark"></i>
                        {errors.email && errors.email.message}
                      </div>
                    )}
                    <span className="inputicon">
                      <i className="fa-solid fa-envelope" />
                    </span>
                  </div>
                  <div
                    className={`position-relative iconinputs ${errors.password ? "geterror" : ""
                      }`}
                  >
                    <label htmlFor="Code" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="Code"
                      placeholder="Enter Password Here"
                      {...register("password", {
                        required: "",
                        minLength: {
                          value: 8,
                          message: " ",
                        },
                      })}
                    />
                    {errors.password && errors.password.message && (
                      <div className="f-error text-danger">
                        <i className="fa-solid fa-xmark"></i>
                        {errors.password && errors.password.message}
                      </div>
                    )}
                    <span className="inputicon">
                      <i className="fa-solid fa-lock" />
                    </span>
                  </div>
                  <p className="forget-pass">
                    If you Forgot password?{" "}
                    <Link to={"/forgetpassword"}>Click Here</Link>{" "}
                  </p>

                  <button
                    className="btns btns--calypso login-btn"
                    onClick={handleSubmit(handleLogin)}
                  >
                    <span>{btnLoader ? <BtnLoader /> : "Send"}</span>
                    {/* <span>{btnLoader ? "Send" : <BtnLoader />}</span> */}
                  </button>
                </form>
              </div>
              <LoginWithCode />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
