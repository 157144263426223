import React, { useEffect,useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { PostData } from "../../ApiHalper/ApiHelper";
import { toast } from "react-toastify";
import { BtnLoader } from "../../commen/BtnLoader";


export const ContectUs = () => {
  const [btnLoader, setBtnLoader] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const userData = useSelector((state) => state?.userInfo?.action?.customer);

  useEffect(() => {
    {
      userData?.firstname &&
        reset({
          name: `${userData?.firstname} ${userData?.lastname}`,
          email: userData?.email,
          mob_no: userData?.mobileno,
        });
    }
  }, [userData]);

  const handleSendQuery = (data) => {
    setBtnLoader(true)
    PostData("customer/home-page/contact-us", data).then((res) => {
      reset({
        query: ''
      });
      if (res?.success) {
        setBtnLoader(false)
        toast.success("Success ! We Connect Soon", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setBtnLoader(false)
    });
  };

  return (
    <>
      <section className="contact" id="Contact">
        <div className="row">
          <div className="col-md-6 contact-for py-4">
            <h3 className="heading52">Contact US For</h3>
            <ul className="text24 d-flex flex-wrap me-md-5 me-4">
              <li className="me-3 mt-3">
                <img
                  src="./assets/media/Star 26.png"
                  alt=""
                  className="img-fluid me-1"
                />
                <span className="fw-bold">Photography</span>
              </li>
              <li className="me-3 mt-3">
                <img
                  src="./assets/media/Star 26.png"
                  alt=""
                  className="img-fluid me-1"
                />
                <span className="fw-bold">Learning</span>
              </li>
              <li className="me-3 mt-3">
                <img
                  src="./assets/media/Star 26.png"
                  alt=""
                  className="img-fluid me-1"
                />
                <span className="fw-bold">PhotoShoot</span>
              </li>
              <li className="me-3 mt-3">
                <img
                  src="./assets/media/Star 26.png"
                  alt=""
                  className="img-fluid me-1"
                />
                <span className="fw-bold">Events</span>
              </li>
              <li className="me-3 mt-3">
                <img
                  src="./assets/media/Star 26.png"
                  alt=""
                  className="img-fluid me-1"
                />
                <span className="fw-bold">VideoShoot</span>
              </li>
            </ul>
          </div>
          <div className="col-md-6 ps-md-5 ps-4 contact-content py-4">
            <div className="vericalalign" />
            <img
              src="./assets/media/black&white.gif"
              alt="capture shot"
              className="img-fluid"
            />
            <p className="text24">
              Write something Services About content this side and the right
              side sec create a small video about his work whatever had done,
              include all work little bit &amp; play in this circle.
            </p>
          </div>
        </div>
        <div className="row contact-form justify-content-between align-items-center">
          <div className="col-md-6">
            <img
              src="./assets/media/contactbg.png"
              alt=""
              className="img-fluid cf-img"
            />
          </div>
          <div className="col-md-5 d-flex align-items-center h-100 pe-0">
            <form className="w-100">
              <div className={`contact-input ${errors.name ? 'geterror' : ''}`}>
                <label htmlFor="name" className="form-label">
                  <i className="fa-solid fa-user"/>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  {...register("name", {
                    required: " ",
                    pattern: {
                      value: /^[a-zA-Z ]{2,30}$/,
                      message: " ",
                    },
                  })}
                />
                {errors.name && errors.name.message && (
                  <div className="f-error">
                    <i className="fa-solid fa-xmark"></i>
                    {errors.name && errors.name.message}
                  </div>
                )}
              </div>
              <div className={`contact-input ${errors.email ? 'geterror' : ''}`}>
                <label htmlFor="email" className="form-label">
                  <i className="fa-solid fa-envelope" />
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  {...register("email", {
                    required: " ",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: " ",
                    },
                  })}
                />
                {errors.email && errors.email.message && (
                  <div className="f-error">
                    <i className="fa-solid fa-xmark"></i>
                    {errors.email && errors.email.message}
                  </div>
                )}
              </div>
              <div className={`contact-input ${errors.mob_no ? 'geterror' : ''}`}>
                <label htmlFor="mobileNo" className="form-label">
                  <i className="fa-solid fa-phone" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="mobileNo"
                  placeholder="Mobile"
                  {...register("mob_no", {
                    required: " ",
                    pattern: {
                      value: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                      message: " ",
                    },
                  })}
                />
                {errors.mob_no && errors.mob_no.message && (
                  <div className="f-error">
                    <i className="fa-solid fa-xmark"></i>
                    {errors.mob_no && errors.mob_no.message}
                  </div>
                )}
              </div>
              <div className={`contact-input ${errors.query ? 'geterror' : ''}`}>
                <label htmlFor="querry" className="form-label">
                  <i className="fa-solid fa-comments"></i>
                </label>
                <textarea
                  className="form-control"
                  id="querry"
                  rows={1}
                  placeholder=" Message"
                  defaultValue={""}
                  {...register("query", {
                    required: " ",
                    minLength: {
                      value: 5,
                      message: " ",
                    },
                  })}
                />
                {errors.query && errors.query.message && (
                  <div className="f-error">
                    <i className="fa-solid fa-xmark"></i>
                    {/* <i className="fa-regular fa-circle-xmark me-1"/> */}
                    {/* <i className="fa-solid fa-exclamation"></i>  */}
                    {/* <i className="fa-solid fa-circle-exclamation"></i> */}
                    {errors.query && errors.query.message}
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="btns btns--calypso"
                onClick={handleSubmit(handleSendQuery)}
              >
                <span>{btnLoader ? <BtnLoader /> : "Send"}</span>
              </button>
            </form>
          </div>
        </div>
      </section>
      {/* end:: contact us */}
      {/* begin:: contact us */}
    </>
  );
};
