import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Login } from "../Auth/Login";
import { ForgetPassword } from "../Auth/ForgetPassword";
import { NewPassword } from "../Auth/NewPassword";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { Folder } from "../pages/tenent/Folder/Folder";
import { Event } from "../pages/tenent/event/Event";
import { Images } from "../pages/tenent/images/Images";
import { EventToken } from "../pages/tenent/eventToken/EventToken";
import Cookies from "js-cookie";
// import { GuestEvent } from "../pages/sharedPages/guestEvent/GuestEvent";
import { GuestFolder } from "../pages/sharedPages/guestFolder/GuestFolder";
import { GuestImages } from "../pages/sharedPages/guestImages/GuestImages";
import Invoice from "../pages/tenent/invoice/Invoice";
import { PageNotFound } from "../commen/404";
import { AllSarviceList } from "../pages/Sarvices/AllSarviceList";
import { SrvcPortoDetail } from "../pages/Sarvices/SrvcPortoDetail";
import { FaceRecogniseImage } from "../pages/tenent/images/FaceRecogniseImage";

const AuthenticatedRoute = ({ element, token }) => {
  return token ? element : <Navigate to="/login" replace />;
};
const GustAuthenticatedRoute = ({ element, token }) => {
  return token ? element : <Navigate to="/login" replace />;
};

export default function AllRoutes() {
  const token = Cookies.get("token");
  const gustToken = Cookies.get("gustToken");

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgetpassword" element={<ForgetPassword />} />
      <Route path="/password/reset/:token" element={<NewPassword />} />
      <Route path="service/List" element={<AllSarviceList />} />
      <Route path="service/Detail" element={<SrvcPortoDetail />} />

      <Route
        path="/profile/Event"
        element={<AuthenticatedRoute element={<Event />} token={token} />}
      />
      <Route
        path="/Event/folder"
        element={<AuthenticatedRoute element={<Folder />} token={token} />}
      />
      <Route
        path="/Event/folder/image"
        element={<AuthenticatedRoute element={<Images />} token={token} />}
      />
      <Route
        path="/Event/Token"
        element={<AuthenticatedRoute element={<EventToken />} token={token} />}
      />
      <Route
        path="/invoices"
        element={<AuthenticatedRoute element={<Invoice />} token={token} />}
      />
         <Route
        path="/faceRecognize"
        element={<AuthenticatedRoute element={<FaceRecogniseImage />} token={token} />}
      />
      {/* <Route
        path="/guest/Event"
        element={
          <GustAuthenticatedRoute element={<GuestEvent />} token={gustToken} />
        }
      /> */}
      {/* <Route path="/guest/Event" element={<GuestEvent />} /> */}

      {/* <Route
        path="/guest/Folder"
        element={
          <GustAuthenticatedRoute element={<GuestFolder />} token={gustToken} />
        }
      /> */}
      <Route path="/guest/Folder" element={<GuestFolder />} />

      <Route
        path="/guest/Images"
        element={
          <GustAuthenticatedRoute element={<GuestImages />} token={gustToken} />
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
